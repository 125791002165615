<template>
  <div class="text-dark-blue form-style">
    <div class="person-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
      <StepTitle :title="$t('personalInfo')" :activeStep="4" v-if="!isShow" />
      <b-form class="ml-0" :class="isShow ? '' : 'mt-5'">
        <Alert :alert="alert" />
        <div class="row">
          <b-form-group class="col-6">
            <label for="name">{{ $t("name") }}</label>
            <b-form-input
              type="text"
              id="name"
              v-model="$v.form.name.$model"
              :state="!$v.form.name.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.name.required">{{
              $t("validateMessages.pleaseName")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="col-6">
            <label for="surname">{{ $t("surname") }}</label>
            <b-form-input
              type="text"
              id="surname"
              v-model="$v.form.surname.$model"
              :state="!$v.form.surname.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.surname.required">{{
              $t("validateMessages.pleaseSurname")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group>
          <label for="date">{{ $t("birth") }}</label>
          <!-- <b-form-input
            type="date"
            id="date"
            v-model="$v.form.birthDate.$model"
            :state="!$v.form.birthDate.$error"
            lang="en-US"
            :max="minYear"
          /> -->
          <!-- <v-date-picker
            v-model="$v.form.birthDate.$model"
            :state="!$v.form.birthDate.$error"
          >
            <template>
              <input id="date" />
            </template>
          </v-date-picker> -->
          <v-date-picker
            v-model="$v.form.birthDate.$model"
            :state="!$v.form.birthDate.$error"
            :locale="$i18n.locale"
            :max-date="dateValidation()"
            :min-date="new Date(1900, 0, 1)"
            is-required
            :popover="{ visibility: 'click' }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
          <b-form-invalid-feedback v-if="!$v.form.birthDate.required">{{
            $t("validateMessages.pleaseBirthDate")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <div class="mt-3">
          <b-form-group v-slot="{ ariaDescribedby }">
            <label for="name">{{ $t("whatsyourGender") }}</label>

            <b-form-radio-group
              id="radio-group-1"
              v-model="$v.form.gender.$model"
              :options="genders"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <p class="font-weight-bold mt-3">{{ $t("address") }}</p>
        <p class="font-size-14 mb-3">
          {{ $t("addressText") }}
          <span class="font-weight-bold">{{ $t("addressRegistration") }}</span>
          {{ $t("addressGermany") }}
        </p>
        <div class="row">
          <b-form-group class="col-8">
            <label for="street">{{ $t("street") }}</label>
            <b-form-input
              type="text"
              id="street"
              v-model="$v.form.address.street.$model"
              :state="!$v.form.address.street.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.address.street.required">{{
              $t("validateMessages.pleaseStreet")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="col-4">
            <label for="apartmentNo">{{ $t("number") }}</label>
            <b-form-input
              type="number"
              id="apartmentNo"
              v-model="$v.form.address.apartmentNo.$model"
              :state="!$v.form.address.apartmentNo.$error"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.address.apartmentNo.required"
              >{{
                $t("validateMessages.pleaseNumber")
              }}</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="row">
          <b-form-group class="col-6">
            <label for="code">{{ $t("postCode") }}</label>
            <b-form-input
              type="text"
              id="code"
              v-model="$v.form.address.postalCode.$model"
              :state="!$v.form.address.postalCode.$error"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.address.postalCode.required"
              >{{
                $t("validateMessages.pleasePostalCode")
              }}</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-if="!$v.form.address.postalCode.minLength"
              >{{
                $t("validateMessages.minPostalCodeText")
              }}</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-if="!$v.form.address.postalCode.maxLength"
              >{{
                $t("validateMessages.maxPostalCodeText")
              }}</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group class="col-6">
            <label for="city">{{ $t("city") }}</label>
            <b-form-input
              type="text"
              id="city"
              v-model="$v.form.address.city.$model"
              :state="!$v.form.address.city.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.address.city.required">{{
              $t("validateMessages.pleaseCity")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group class="iban-text">
          <div
            class="d-flex align-items-center optional"
            @mouseleave="showIbanInfo = false"
          >
            <label for="iban"
              >{{ $t("iban") }}
              <font-awesome-icon
                :icon="faInfoCircle"
                @mouseover="() => ibanInfo()"
            /></label>
          </div>
          <div v-if="showIbanInfo" class="document-message">
            {{ $t("ibanInfoText") }}
          </div>
          <b-form-input
            type="text"
            id="iban"
            v-model="$v.form.iban.$model"
            :state="!$v.form.iban.$error"
          />
          <b-form-invalid-feedback v-if="!$v.form.iban.required">{{
            $t("validateMessages.ibanRequired")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.iban.maxLength">{{
            $t("validateMessages.ibanMaxLength")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.iban.minLength">{{
            $t("validateMessages.ibanMinLength")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <div
            class="d-flex align-items-center optional"
            @mouseleave="showTaxIdentifyNo = false"
          >
            <label for="idNo"
              >{{ $t("taxId") }}
              <font-awesome-icon
                :icon="faInfoCircle"
                @mouseover="() => showTaxIdentifyNoInfo()"
            /></label>
            <p class="text-muted font-size-14">{{ $t("optional") }}</p>
          </div>
          <div v-if="showTaxIdentifyNo" class="document-message">
            {{ $t("taxNoText") }}
          </div>
          <b-form-input
            type="text"
            id="idNo"
            v-model="$v.form.taxIdentifyNo.$model"
            :state="!$v.form.taxIdentifyNo.$error"
          />
          <b-form-invalid-feedback v-if="!$v.form.taxIdentifyNo.minLength">{{
            $t("validateMessages.idMinLength")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";
import { validationMixin } from "vuelidate";
import Alert from "../base/alert/Alert.vue";
import { ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import DatePicker from "v-calendar/lib/components/date-picker.umd";

const selectedColor = ref("##57c6ff");

const {
  required,
  maxLength,
  minLength,
  maxValue,
} = require("vuelidate/lib/validators");

export default {
  name: "PersonForm",
  components: {
    StepTitle,
    Alert,
    DatePicker,
    FontAwesomeIcon,
  },
  data() {
    return {
      genders: [
        { text: this.$t("male"), value: "1" },
        { text: this.$t("female"), value: "2" },
      ],
      form: {},
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      minYear: "",
      modelConfig: {
        type: "number",
      },
      faInfoCircle,
      showIbanInfo: false,
      showTaxIdentifyNo: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      surname: {
        required,
      },
      birthDate: {
        required,
      },
      gender: {
        required,
      },
      iban: {
        required,
        maxLength: maxLength(22),
        minLength: minLength(22),
      },
      taxIdentifyNo: {
        minLength: minLength(11),
      },
      address: {
        street: {
          required,
        },
        apartmentNo: {
          required,
        },
        postalCode: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
        city: {
          required,
        },
      },
    },
  },
  props: {
    isShow: false,
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    CHECK_STEP() {
      if (this.form.iban != null && this.form.iban != "") {
        if (this.ibanValidation(this.form.iban)) {
          this.$v.form.$touch();
          return !this.$v.form.$anyError;
        } else {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "ibanFormat";
        }
      } else {
        this.$v.form.$touch();
        return !this.$v.form.$anyError;
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 2000);
    },
    ibanValidation(val) {
      return /^([a-zA-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
        val
      );
    },
    dateValidation() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear() - 16;
      return new Date(yyyy, mm, dd);
    },
    ibanInfo() {
      this.showIbanInfo = true;
    },
    showTaxIdentifyNoInfo() {
      this.showTaxIdentifyNo = true;
    },
  },
  created() {
    this.form = this.data;
    this.dateValidation();
  },
};
</script>

<style></style>
