<template>
  <div class="step-title-comp">
    <div class="step-title-content">
      <h2 class="step-title">{{ title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    activeStep: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
.step-title-comp {
  margin-bottom: 8px;
  .step-title-content {
    display: flex;

    .step-number {
      height: 40px;
      width: 40px;
      padding: 22px;
      border-radius: 50%;
      border: 1px solid #57c6ff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      margin-bottom: 0px;
    }

    .step-title {
      color: #01295f;
      margin-bottom: 0px;
    }
  }
}
</style>