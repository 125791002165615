<template>
  <div class="account-settings form-style" v-if="!pageLoading">
    <SelectionMenu />
    <Menu />
    <div
      class="col-12 col-md-8 col-lg-8 offset-md-2 offset-lg-2 mt-5 person-info-form"
    >
      <h2 class="text-center mb-5">{{ $t("accountSettings") }}</h2>
      <b-form
        class="mt-3 col-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3"
        @submit.prevent="updateAcoountInfo"
      >
        <Alert :alert="alert" />
        <b-form-group>
          <label for="email">{{ $t("emailAddress") }}</label>
          <b-form-input
            type="email"
            inputmode="email"
            id="email"
            v-model="$v.form.email.$model"
            :state="!$v.form.email.$error"
            disabled
          />
          <b-form-invalid-feedback v-if="!$v.form.email.required">{{
            $t("validateMessages.pleaseEmail")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="password-icon">
          <label for="password">{{ $t("password") }}</label>
          <b-form-input
            :type="!passEye ? 'password' : 'text'"
            id="password"
            v-model="form.password"
            placeholder="******"
          />
          <span @click="passEye = !passEye">
            <font-awesome-icon
              :icon="passEye ? faEye : faEyeSlash"
              class="input-icon"
            />
          </span>
        </b-form-group>
        <b-form-group :label="$t('language')">
          <b-form-select
            v-model="selectedLang"
            size="lg"
            class="lang-select-box"
            @change="changeLang"
          >
            <b-form-select-option
              :value="item.value"
              v-for="(item, index) in optionsLang"
              :key="index"
              >{{ $t(item.text) }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <!-- <b-form-group
          :label="$t('deleteAccount')"
          :state="!$v.form.isActive.$model"
        >
          <b-form-select
            v-model="form.isActive"
            size="lg"
            class="lang-select-box"
          >
            <b-form-select-option
              :value="item.value"
              v-for="(item, index) in optionsStatus"
              :key="index"
              >{{ $t(item.text) }}</b-form-select-option
            >
          </b-form-select>
          <b-form-invalid-feedback v-if="!$v.form.isActive.required">{{
            $t("validateMessages.pleaseAccount")
          }}</b-form-invalid-feedback>
        </b-form-group> -->
        <button type="submit" class="blue-button w-100 mt-3">
          <span v-if="!loading">{{ $t("save") }}</span>
          <b-spinner variant="light" class="p-0" v-else></b-spinner>
        </button>
        <div class="mt-4">
          <b-alert
            variant="secondary"
            show
            class="d-flex align-items-center justify-content-between pt-0 pb-0"
            ><span>{{ $t("frozenText") }}</span>
            <b-button @click="accountFreeze" class="mr-0">{{
              $t("accountFrozen")
            }}</b-button></b-alert
          >
        </div>
        <div class="mt-4">
          <b-alert
            variant="danger"
            show
            class="d-flex align-items-center justify-content-between pt-0 pb-0"
            ><span>{{ $t("accountDeleteText") }}</span>
            <b-button variant="danger" class="mr-0" @click="accountDelete">{{
              $t("deleteMyAction")
            }}</b-button></b-alert
          >
        </div>
      </b-form>
    </div>
  </div>
  <div id="preloader" v-else>
    <div id="status">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import PersonForm from "../components/register/PersonForm.vue";
import Menu from "../components/start/base/Menu.vue";
import SelectionMenu from "../components/start/base/SelectionMenu.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getUserInfo, putUserInfoComeFromForm } from "../services/modules/User";
import Alert from "../components/base/alert/Alert.vue";
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

const { required } = require("vuelidate/lib/validators");

export default {
  components: {
    PersonForm,
    Menu,
    SelectionMenu,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      optionsLang: [
        { value: "de", text: "german" },
        { value: "tr", text: "turkish" },
        { value: "en", text: "english" },
      ],
      selectedLang: "tr",
      optionsStatus: [
        { value: true, text: "active" },
        { value: false, text: "notActive" },
      ],
      selectedStatus: true,
      faEye,
      faEyeSlash,
      form: {
        email: "",
        password: null,
        phoneNo: null,
        name: null,
        surname: null,
        birthDate: null,
        address: null,
        iban: "",
        taxIdentifyNo: "",
        taxYear: null,
        marriageStatus: null,
        isActive: true,
        isDelete: true,
      },
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      pageLoading: false,
      loading: false,
      passEye: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
      },
      isActive: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["activeUser"]),
  },
  methods: {
    ...mapActions(["SET_LOGOUT_ACTION"]),
    async accountInfo() {
      this.pageLoading = true;
      const response = await getUserInfo(this.activeUser.id);

      if (response.message === "OK") {
        this.form = {
          ...response.data,
          password: "",
        };
      }
      this.pageLoading = false;
    },
    async updateAcoountInfo() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const response = await putUserInfoComeFromForm(this.activeUser.id, {
          Password: this.form.password,
        });

        if (response.message === "OK") {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "validateMessages.updateAccount";

          setTimeout(() => {
            this.SET_LOGOUT_ACTION();
            this.$router.push("/anmeldung");
          }, 2000);
        } else {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "validateMessages.notUpdateAccount";
        }

        setTimeout(() => {
          this.alert.show = false;
        }, 2000);
        this.loading = false;
      }
    },
    accountFreeze() {
      this.$bvModal
        .msgBoxConfirm(this.$t("freezeAccountText"), {
          title: "",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.form.isActive = false;
            const response = await putUserInfoComeFromForm(this.activeUser.id, {
              IsActive: false,
            });

            if (response.message === "OK") {
              this.alert.show = true;
              this.alert.variant = "success";
              this.alert.message = "successFrozenText";
            }

            setTimeout(() => {
              this.alert.show = false;
              this.SET_LOGOUT_ACTION();
              this.$router.push("/");
            }, 2000);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    accountDelete() {
      this.$bvModal
        .msgBoxConfirm(this.$t("deleteAccountText"), {
          title: "",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.form.isDelete = false;
            const response = await putUserInfoComeFromForm(this.activeUser.id, {
              IsDelete: true,
            });

            if (response.message === "OK") {
              this.alert.show = true;
              this.alert.variant = "success";
              this.alert.message = "successAccountDeleteText";
            }

            setTimeout(() => {
              this.alert.show = false;
              this.SET_LOGOUT_ACTION();
              this.$router.push("/");
            }, 2000);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    changeLang() {
      this.$i18n.locale = this.selectedLang;
      localStorage.setItem("lang", this.selectedLang);
    },
  },
  mounted() {
    const lang = localStorage.getItem("lang");
    this.selectedLang = lang || "tr";
    this.accountInfo();
  },
};
</script>

<style lang="scss">
.account-settings {
  .col-form-label {
    font-weight: bold;
    padding: 0px 0px 0px 10px;
    margin-bottom: 0.5rem;
  }
  .custom-select-lg {
    border-radius: 20px;
    border: 2px solid #01295f;
    padding: 0 1rem;
  }
  .password-icon {
    position: relative;

    .input-icon {
      position: absolute;
      top: 60%;
      right: 1.2rem;
      color: #01295f;
    }
  }

  // .account-buttons {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: 100%;

  //   button {
  //     width: 100%;
  //     max-width: 200px;
  //   }
  // }

  .modal-content {
    border-radius: 10px !important;
  }
}
</style>